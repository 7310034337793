(function ($) {

  'use strict';

  var $stickyNav = $('.sticky-navigation');
  var stickyNavShown = false;

  function initLoader() {
    $(window).load(function () {
      $('#status').fadeOut();
      $('#preloader').delay(350).fadeOut('slow');
      $('body').delay(350).css({
        'overflow': 'visible'
      });
    });
  }

  function syncScreenshotListItem($el) {
    var $items = $el.closest('.row').find('.feature-list li');
    var $item = $($items.get(this.currentItem));

    $items.removeClass('active');
    if ($item.length > 0) {
      $item.addClass('active');
    }
  }

  function initScreenshotSlider() {
    $(".owl-screenshot").owlCarousel({
      singleItem: true,
      lazyLoad: true,
      autoPlay: true,
      pagination: false,
      stopOnHover: true,
      navigation: true,
      afterInit: syncScreenshotListItem,
      beforeMove: syncScreenshotListItem
    });
  }

  function initScrollMenu() {
    $('body > header a').on('click', function () {
      $('body > header .navbar-collapse').collapse('hide');
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 600) {
        stickyNavShown = true;
        $stickyNav.css({ "top": '0px' });
      } else {
        stickyNavShown = false;
        $stickyNav.css({ "top": '-100px' });
      }
      return false;
    });
  }

  function initPageScroll() {
    $('.page-scroll').on('click', function (event) {
      event.preventDefault();
      var $section = $($(this).attr('href'));
      if ($section.length) {
        var offset = stickyNavShown ? 75 : 0;
        $('html, body').stop().animate({
          scrollTop: $section.offset().top - offset
        }, 1500, 'easeInOutQuart');
      }
    });
  }
  
  function initContactForm() {
    if (window.location.href.indexOf('submit=true') > -1) {
      $("html, body").animate({ scrollTop: $(document).height() }, 'slow');
      var $row = $('#contact-form-row');
      $row.empty();
      $row.append('<h2 class="thank-you">Thank You!</h2>');
      $row.append('<h4 class="contact-soon">We will contact you soon</h4>');
    }
  }

  function formListener() {
    $( "#contact-form" ).on( "submit", function( event ) {
      event.preventDefault();
      var formData = $( this ).serializeArray();

      var data = {};

      formData.forEach(function (v, k) {
        data[v.name] = v.value;
      });

      console.log('TEST');
      console.log(data);

      var text = 'Web app form data \n';

      if (data.firstname){
        text += 'Firstname: ' + data.firstname + '\n';
      }

      if (data.lastname){
        text += 'Lastname: ' + data.lastname + '\n';
      }

      if (data.company){
        text += 'Company: ' + data.company + '\n';
      }

      if (data.email){
        text += 'Email: ' + data.email + '\n';
      }

      if (data.message){
        text += 'Message: ' + data.message + '\n';
      }

      var form = new FormData();
      form.append("from", "mbkane04@gmail.com");
      form.append("to", "tisaev@reaktivate.com");
      form.append("subject", "Web app contact from");
      form.append("text", text);

      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://api:89dd0911225f2460970a23c2f83671fc-3939b93a-6a359c97@api.mailgun.net/v3/sandbox76f9cbb616fc4565b7f1933208e2108b.mailgun.org/messages",
        "method": "POST",
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form,
        "headers": {
          "Access-Control-Allow-Origin":"*"
        },
        xhrFields: {
          withCredentials: true
        }
      };

      $.ajax(settings).done(function (res) {
        console.log(res);
      });
    });
  }

  function init() {
    initLoader();
    initScreenshotSlider();
    initPageScroll();
    initScrollMenu();
    initContactForm();
    formListener();
  }

  init();

})(jQuery);